var nav = function(id) {
  this.class= 'is-active';
  this.nav = document.querySelector(id);
  this.toggle = this.nav.querySelector(id + '-toggle');

  this.toggle.onclick = function() {
    this.toggleClass(this.nav, this.class);
    return false;
  }.bind(this);

  this.toggleClass = function($el, $class) {
    if($el.classList.contains($class)) {
      $el.classList.remove($class);
    } else {
      $el.classList.add($class);
    }
  }
};

new nav('.nav-primary');