var dropdown = function(id) {
  this.inputfields = document.querySelectorAll(id);

  this.init = function() {
    for(var $i = 0; $i < this.inputfields.length; $i++) {
      var $inputfield = this.inputfields[$i],
          $input = $inputfield.querySelector('select'),
          $list = $inputfield.querySelector('ul'),
          $name = $inputfield.getAttribute('data-name');

      $input.onchange = function(event) {
        if(this.selectedIndex > 0) {
          var $options = this.options[this.selectedIndex];
              $options.disabled = true;

          var $li = document.createElement('li');
              $li.innerHTML = this.options[this.selectedIndex].innerHTML;

          var $label = document.createElement('label');
              $label.classList.add('input-dropdown-list-remove');

          var $checkbox = document.createElement('input');
              $checkbox.type = 'checkbox';
              $checkbox.name = $name;
              $checkbox.checked = true;
              $checkbox.value = this.options[this.selectedIndex].value;

          var $i = document.createElement('i');
    
          $label.appendChild($checkbox);
          $label.appendChild($i);
          $li.appendChild($label);

          $checkbox.onchange = function() {
            $options.disabled = false;
            $li.remove();
          };

          $list.appendChild($li);

          this.selectedIndex = 0;
        }
      }
    }
  }
};
